import { ActivityFeelingsOptions, ActivityLabels, ActivityTimeOptions, InsideSchoolLocations, OutsideSchoolLocation, WithWhomChoices, OutsideLocation } from "./activityConstants";


export const API_ROOT = process.env.REACT_APP_BASE_API_URL || '';
export const API_SLUG = '/api/mobile/';
export const S3_ROOT = process.env.REACT_APP_S3_URL;


export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || '';
export const  GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';



export const getApiUrl = (serviceType: String) => {
    return `${API_ROOT}${API_SLUG}${serviceType}`;
}

export const titleMap = {
    1 : {
        title : 'Goal'
    },
    2 : {
        title : 'Duration'
    },
    3 : {
        title : 'Activities'
    },
    4 : {
        title : 'Preference'
    },
    5 : {
        title : 'Current Activities'
    },
    6 : {
        title : 'Routine'
    },
    7 : {
        title : 'Routine'
    },
    8 : {
        title : 'Hurdles'
    },
    9 : {
        title : 'Positive Influence'
    }
}

export const sideMenuItems = [
    // {
    //     title: 'What’s new?',
    //     key: 'newActivity'
    // },
    {
        title: 'miProfile',
        key: 'miProfile'
    },
    {
        title: 'miDashboard',
        key: 'miDashboard'
    },
    {
        title: 'miSurvey',
        key: 'about'
    },
    {
        title: 'miTarget',
        key: 'miTarget'
    },
    {
        title: 'miFriends',
        key: 'miFriends'
    },
    {
        title: 'Settings',
        key: 'settings'
    },
    // {
    //     title: 'Security questions',
    //     key: 'security'
    // },
    // {
    //     title: 'Change password',
    //     key: 'changePassword'
    // },
    {
        title: 'miTutorials',
        key: 'tutorials'
    },
    {
        title: 'Feedback',
        key: 'feedback'
    },
  
];

export const onBoardingSteps = [
    'Goal',
    'Duration',
    'Activities',
    'Preference',
    'Current Activities',
    'Routine',
    'Hurdles',
    'Positive influence'
];

export const addActivityStep = [
    'Activity',
    'Duration',
    'Who with',
    'When and where',
    'How did the activity feel',
    'Reflection',
];


export const weekDays = {
    '0': 'S',
    '1': 'M',
    '2': 'T',
    '3': 'W',
    '4': 'T',
    '5': 'F',
    '6': 'S'  
}

export const WithWhoData = [
    {
        id: 2,
        title: 'I DID THE ACTIVITY',
        options: WithWhomChoices.map((s) => ({id: s.type, title: s.label}))
    },
];


export const whenWhereData = [
    {
        id: 3,
        title: 'AT SCHOOL',
        options: InsideSchoolLocations.map((s) => ({id: s.type, title: s.label}))
    },
    {
        id: 3,
        title: 'OUT OF SCHOOL',
        options: OutsideSchoolLocation.map((s) => ({id: s.type, title: s.label}))

    },
];

export const whenWhereDataWithoutSchool = [
    {
        id: 3,
        title: 'WHERE',
        options: OutsideLocation.map((s) => ({id: s.type, title: s.label}))

    },
];

export const URL = window.location.href.substring(0, 22) + "#/";

export const ActivityLabelMap = ActivityLabels.reduce((acc, curr) => {
    return {...acc, [curr.type]: curr.label};
}, {});




export const addActivityQuestions = [
    {
        step: 1,
        text: 'What activity did you do?',
        subTitle: '',
        multiselect: false,
        choices: ActivityLabels.map((a) => ({ text: ActivityLabelMap[a.type] ? ActivityLabelMap[a.type] : a.type, description: '', image: `${S3_ROOT}/assets/activity-icons/${a.type.toLowerCase()}.png`, id: a.type }))


    },
    {
        step: 2,
        text: 'How long were you active ?',
        subTitle: '',
        multiselect: false,
        choices: ActivityTimeOptions.map((a) => ({ text: a.label, description: '', image: `${S3_ROOT}/assets/times/${a.duration}min.svg`, id: a.duration }))

    },
    {
        step: 3,
        text: 'Who did you do this activity with?',
        subTitle: '',
        multiselect: false,
        choices: []
    },
    {
        step: 4,
        text: 'When and where were you active?',
        subTitle: '',
        multiselect: false,
        choices: []
    },
    {
        step: 5,
        text: 'How did the activity feel?',
        subTitle: '',
        multiselect: false,
        choices: ActivityFeelingsOptions.map((a) => ({ text: a.label, description: '', image: `${S3_ROOT}/assets/emotions/${a.type.toLowerCase()}.svg`, id: a.type }))

      
    },
   
    {
        step: 6,
        text: 'Reflection',
        subTitle: '',
        multiselect: false,
        choices: [],
    },
];

export const msalConfig = {
    auth: {
      clientId: MICROSOFT_CLIENT_ID,
      authority: "https://login.microsoftonline.com/common", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "/",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
    prompt: 'select_account',
};


export const miInfoSideItems = [
    // {
    //     title: 'What’s new?',
    //     key: 'newActivity'
    // },
    {
        title: 'miBio',
        key: 'miBio'
    },
    {
        title: 'miSurvey',
        key: 'miSurvey'
    },
  
];

export const miFriendsSideItems = [
    // {
    //     title: 'What’s new?',
    //     key: 'newActivity'
    // },
    {
        title: 'miFriends',
        key: 'miFriends'
    },
    {
        title: 'Discoverable',
        key: 'discoverable'
    },
  
];

export const miDashboardSideItems = [
    {
        title: 'miStats',
        key: 'statistics'
    },
    {
        title: 'miRecords',
        key: 'miRecords'
    },
    
  
];

export interface IDateFilterOptions {
    id: string;
    label: string;
}
export const DateFilterOptions = [
    {id: 'WEEKLY', label: 'Weekly'},
    {id: 'MONTHLY', label: 'Monthly'},
    {id: 'YEARLY', label: 'Yearly'},
];

export interface IDateOptions {
    id: number;
    label: string;
}

export const MonthArr = [
    {id: 0, label: 'January'},
    {id: 1, label: 'February'},
    {id: 2, label: 'March'},
    {id: 3, label: 'April'},
    {id: 4, label: 'May'},
    {id: 5, label: 'June'},
    {id: 6, label: 'July'},
    {id: 7, label: 'August'},
    {id: 8, label: 'September'},
    {id: 9, label: 'October'},
    {id: 10, label: 'November'},
    {id: 11, label: 'December'},
];

export const YearArr = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1970; i -= 1) {
        years.push({id: i, label: i.toString()});
    }
    return years;
};

// export const levels = {
//     1: 1250,
//     2: 2750,
//     3: 4500,
//     4: 6500,
//     5: 8750,
//     6: 11250,
//     7: 14000,
//     8: 17000,
//     9: 20250,
//     10: 23750,
//     11: 27500,
//     12: 31500,
//     13: 35750,
//     14: 40250,
//     15: 45000,
//     16: 50000,
//     17: 55250,
//     18: 60750,
//     19: 66500,
//     20: 72500,
//     21: 78750,
//     22: 85250,
//     23: 92000,
//     24: 99000,
//     25: 106250,
//     26: 113750,
//     27: 121500,
//     28: 129500,
//     29: 137750,
//     30: 146250,
//     31: 155000,
//     32: 164000,
//     33: 173250,
//     34: 182750,
//     35: 192500,
//     36: 202500,
//     37: 212750,
//     38: 223250,
//     39: 234000,
//     40: 245000,
//     41: 256250,
//     42: 267750,
//     43: 279500,
//     44: 291500,
//     45: 303750,
//     46: 316250,
//     47: 329000,
//     48: 342000,
//     49: 355250,
//     50: 368750,
//     51: 382500,
//     52: 396500,
//     53: 410750,
//     54: 425250,
//     55: 440000,
//     56: 455000,
//     57: 470250,
//     58: 485750
// }

// export const getLevel = (points: number) => {
//     for(let key in levels){
//         if(parseInt(key)==1 && points<levels[key]){
//             return {level: parseInt(key), start: 0, end: levels[key]}
//         }
//         else if(points<levels[key] && points>levels[parseInt(key)-1]){
//             return {level: parseInt(key), start: levels[parseInt(key)-1], end: levels[key]}
//         }
//     }
//     return {level: 1, start: 0, end: levels[1]}
// }