import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F4511E',
		},
		secondary: {
			main: '#FFFFFF',
		},
		error: {
			main: '#F4511E'
		}
	},
	typography: {
		fontFamily: [
			'Nunito',
			'sans-serif',
		].join(','),
		button: {
			textTransform: 'none',
		},
	},
});

export default responsiveFontSizes(theme);
